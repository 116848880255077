import i18n from '@/i18n/index'
// import constant from '@/utils/constant.js'

export default {
  formData: [{
    title: i18n.t('form.store.generalInformation'),
    name: 0,
    createDateItem: [{
      span: 24,
      prop: 'name',
      name: 'input',
      label: i18n.t('form.groupUser.name'),
      Object: {}
    }, {
      span: 8,
      prop: 'group_type',
      name: 'select',
      options: [],
      label: i18n.t('form.groupUser.groupType'),
      placeholder: i18n.t('placeholder.defaultSelect'),
      Object: {
        label: 'label',
        disabled: true,
        nextChange: true,
        nextChangeProp: 'group_type'
      }
    }, {
      span: 8,
      prop: 'wholesale_pricing_tier_id',
      name: 'select',
      options: [],
      label: i18n.t('form.groupUser.priceTier'),
      placeholder: i18n.t('placeholder.defaultSelect'),
      Object: {
        filterable: true,
        label: 'name',
        value: 'id',
        visible: 'group_type',
        visibleValue: 'wholesale'
      }
    }]
  }]
}
